import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    price: '',
    orderNo: '',
    tradeNo: '',
    currency: '',
    mId: '',
    nextLogo: '',
    expireTime: '',
    payInfo: '',
    virturlAccount: '',
    referenceNumber: '',
    ddpyid: '',
    returnUrl: '',
    details: [],
  },
  mutations: {
    setPrice(state, number) {
      state.price = number
    },
    setCurrency(state, number) {
      state.currency = number
    },
    setMId(state, number) {
      state.mId = number
    },
    setOrderNo(state, number) {
      state.orderNo = number
    },
    setTradeNo(state, number) {
      state.tradeNo = number
    },
    setNextLogo(state, content) {
      state.nextLogo = content
    },
    setPayInfo(state, content) {
      state.payInfo = content
    },
    setPayerAddress(state, content) {
      state.payerAddress = content
    },
    setPayeeAddress(state, content) {
      state.payeeAddress = content
    },
    setTrxId(state, content) {
      state.trxId = content
    },
    setPaidAmount(state, content) {
      state.paidAmount = content
    },
    setExpireTime(state, content) {
      state.expireTime = content
    },
    setReturnUrl(state, content) {
      state.returnUrl = content
    },
    setDetails(state, content) {
      state.details = content
    },
      setVirturlAccount(state, content) {
          state.virturlAccount = content
      },
      setReferenceNumber(state, content) {
          state.referenceNumber = content
      },
    setDdpyid(state, content) {
      state.ddpyid = content
    }

  },
  actions: {
    setPrice(ctx, number) {
      ctx.commit('setPrice', number)
    },
    setCurrency(ctx, number) {
      ctx.commit('setCurrency', number)
    },
    setMId(ctx, number) {
      ctx.commit('setMId', number)
    },
    setTradeNo(ctx, number) {
      ctx.commit('setTradeNo', number)
    },
    setOrderNo(ctx, number) {
      ctx.commit('setOrderNo', number)
    },
    setNextLogo(ctx, content) {
      ctx.commit('setNextLogo', content)
    },
    setPayInfo(ctx, content) {
      ctx.commit('setPayInfo', content)
    },
    setPayerAddress(ctx, content) {
      ctx.commit('setPayerAddress', content)
    },
    setPayeeAddress(ctx, content) {
      ctx.commit('setPayeeAddress', content)
    },
    setTrxId(ctx, content) {
      ctx.commit('setTrxId', content)
    },
    setPaidAmount(ctx, content) {
      ctx.commit('setPaidAmount', content)
    },
    setExpireTime(ctx, content) {
      ctx.commit('setExpireTime', content)
    },
    setReturnUrl(ctx, content) {
      ctx.commit('setReturnUrl', content)
    },
    setDetails(ctx, content) {
      ctx.commit('setDetails', content)
    },
      setVirturlAccount(ctx, content) {
          ctx.commit('setVirturlAccount', content)
      },
      setReferenceNumber(ctx, content) {
          ctx.commit('setReferenceNumber', content)
      },
    setDdpyid(ctx, content) {
      ctx.commit('setDdpyid', content)
    }

  }
})
